function initialState() {
  return {
    messages: [],
    tourRecord: null,
    prospect: null
  };
}

const getters = {
  messages: (state) => state.messages,
  tourRecord: (state) => state.tourRecord,
  prospect: (state) => state.prospect
};

const mutations = {
  ADD_CHAT_MESSAGE(state, payload) {
    state.messages.push(payload);
  },
  SET_TOUR_RECORD(state, payload) {
    state.tourRecord = payload;
  },
  SET_PROSPECT(state, payload) {
    state.prospect = payload;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations
};
