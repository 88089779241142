export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $currentUser() {
          return this.$store.getters['user/currentUser'];
        }
      }
    });
  }
};
